import { Component, inject, Signal, computed } from '@angular/core';
import { ActivatedRoute, Data, Params, Router } from '@angular/router';
import { AssociationService } from 'src/app/core/services/association.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { AvailabilityService } from 'src/app/core/services/availability.service';
import { AssociationCollection, AssociationComplete } from 'src/app/shared/models/association.model';
import { PriceScaleCollection } from 'src/app/shared/models/availabilty/section.model';
import { Event } from 'src/app/shared/models/event.model';
import { Package } from 'src/app/shared/models/package.model';
import { User } from 'src/app/shared/models/user.model';

@Component({
  selector: 'app-select-friends',
  templateUrl: './select-friends.component.html',
  styleUrls: ['./select-friends.component.css'],
})
export class SelectFriendsComponent {

  constructor(
   private availability: AvailabilityService<Event | Package, PriceScaleCollection>,
  )
  {}
  
  //SERVICES
  private  router:              Router                 = inject(Router);              // Servicio Router
  private  route:               ActivatedRoute         = inject(ActivatedRoute);      // Servicio ActivatedRoute
  private  authService:         AuthService            = inject(AuthService);         // Servicio Auth
  private  associationService:  AssociationService     = inject(AssociationService);  // Servicio Associations
  
  //DATA
  protected  user:              User                                    = this.authService.userData() as User;           // Datos del Usuario 
  protected  data:              Data                                    = this.route.snapshot.data;                      // Datos de la Ruta
  protected  params:            Params                                  = this.route.snapshot.queryParams;               // Parametros de la ruta
  protected  entity:            Signal<Event | Package | null>          = this.availability.entitySelected;              // Signal | Devuelve el evento o el paquete seleccionado
  protected  associations:      Signal<AssociationCollection|undefined> = this.associationService.associationsComplete;  // Signal | Devuelve (cuando resuelve) las asociaciones transformadas en objeto llave valor. No Tipo porque es super largo.
  protected  associationsData:  Signal<AssociationComplete[]>           = this.associationService.associationsArray;     // Signal | Devuelve (cuando resuelve) las asociaciones como array. 
  protected  selectedUsers:     number[]                                = [];                                            // Usuarios seleccionados
  
  //COMPUTED
  protected  event:             Signal<Event>                           = computed(() => this.entity() as Event);                   // Signal | Devuelve el evento seleccionado
  protected  package:           Signal<Package>                         = computed(() => this.entity() as Package);                 // Signal | Devuelve el paquete seleccionado
  protected  isMembership:      Signal<boolean>                         = computed(() => this.entity()?.inventoried ? false : true); // Computed | Determina si el evento es del tipo Membership
   
  //GETTERS
  /**
   * Devuelve la cantidad de usuarios hay seleccionados. 
   */
  protected get quantitySelected(): number {
    return this.selectedUsers.length;
  }

  protected get type(): 'event' | 'package' | Promise<boolean> {
    const key: string = Object.keys(this.params).filter(key => key != 'venue' && key != 'transaction')[0];

    switch(key){
      case 'event':
        return 'event';
      case 'package':
        return 'package';
      default:
        return this.router.navigate(['']);
    }
  }

  protected get isEvent(): boolean {
    return this.type === 'event' ? true : false;
  }

  protected get isPackage(): boolean {
    return this.type === 'package' ? true : false;
  }
  
  //METHODS
  /**
   * Recoge los IDs enviados desde el componente Friends List y los mete dentro del arreglo de **selectedUsers**.
   * @param { number[] } selectedUsersId 
   */
  protected updateSelected(selectedUsersId: number[]): void {
    this.selectedUsers = selectedUsersId;
  }

  /**
   * Formatea los parametros de la URL añadiendo los ids de los usuarios seleccionados. Si el propio usuario se ha seleccionado, se transforma en un asociado para poder trabajar con el. 
   * Finalmente, envia al siguiente step en el flow. 
   * 
   * 1. Cargamos los seleccionados dentro del objeto Data. 
   * 2. Si el propio usuario logueado se encuentra en ese objeto, se transforma como asociado (friend & family)
   * 3. Iteramos en el objeto Data y pasamos el id (**tdc_info.id**) como parametro. 
   * 4. Navega al siguiente step.
   */
  protected nextStep(): void {
    const data:   AssociationCollection = {},
          associations    = this.associations() as AssociationCollection,
          userId: number  = this.user.tdc_info.id,
          params: any     = {
            associations: [],
          }
    
    let route;

    if(this.isEvent){
      params['event'] = this.entity()!.id;
      params['venue'] = this.entity()!.venue;
      // Al ser un componente compartido entre Buy Tickets y Exchange, comprobamos si estamos en una u otra vista.
      route = !location.href.includes('exchange') ? '/buy-tickets/select-seat' : '/exchange/select-seat';
    }

    if(this.isPackage){
      params['package'] = this.entity()?.id;
      params['venue']   = this.entity()?.venue;
      route = '/buy-packages/select-seat';
    }
    
    // Si existe parametro de Transaction, lo añadimos
    if(this.params['transaction']){
      params.transaction = this.params['transaction'];
    }
    
    // Cargamos los usuarios seleccionados
    this.selectedUsers.forEach(user => data[user] = associations[user]);

    // Si el usuario actual está seleccionado, lo transformamos a Association y añadimos al objeto
    if(this.selectedUsers.includes(userId)){  
      const userAsAssociation: AssociationComplete = {
        id:           this.user.tdc_info.id,
        name:         this.user.tdc_info.account_name,
        associate_id: this.user.tdc_info.id,
        tdc_info:     this.user.tdc_info,
      };
      data[userId] = userAsAssociation;
    }
    
    // Pasamos los datos del objeto al parametro associations 
    Object.values(data).forEach(users => !Array.isArray(users) ? params.associations.push(users.tdc_info.id) : false);

    // Guardamos Asociados Seleccionados de manera global
    this.associationService.setSelected(data);
    
    // Navegamos a la siguiente página
    this.router.navigate([route],{queryParams: params});
  }
}
