<div [ngClass]="{'rounded-xl absolute  shadow-lg  transition-all scale-105' : show}"  class="z-10 venue-selector transition-all duration-500 ease-in-out dark:shadow-main/30 max-h-64 overflow-y-auto"> 
    <!-- TITLE -->
    <div (click)="toggleSelect()" [ngClass]="{'mb-3' : show}" class="flex justify-center relative">
        <p [ngClass]="{'-translate-x-[calc(50vw-79.5%)] sm:-translate-x-[calc(48vw-79.5%)]  md:-translate-x-[calc(45vw-79.5%)]' : show}" class="w-fit transition duration-200 delay-75 ease-in">
            <!-- Venues -->
            <span *ngIf="venues">
                {{show ? 'Select Event Type' : selectedVenue | titlecase}}
            </span>

            <!-- Sections -->
            <span *ngIf="priceScales">
                {{show ? 'Select Price Scale' : selectedPriceScale | titlecase}}
            </span>
        </p>
        <i [ngClass]="{'rotate-180': show}" class="absolute right-5 transition-transform ease-in duration-200">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
            </svg>  
        </i>
    </div>
    <!-- VENUES -->
    <ng-container *ngIf="venues">
        <div [ngClass]="show ? 'block opacity-100 pb-3' : 'absolute opacity-0 -z-10'" class="flex flex-col px-5 h-auto gap-y-3 justify-around transition-opacity duration-75">
            
            <span *ngFor="let venue of venues | keyvalue"
                  (click)="selectVenue(venue.value.name)" 
                  [ngClass]="{'text-secondary': venue.value.active, 'opacity-100': show }"
                  class="text-darkgray opacity-0 duration-200 transition-opacity delay-150 ease-in">
                
                  {{venue.value.name | titlecase}}
            
            </span>

        </div>
    </ng-container>
    <!-- SECTIONS -->
    <ng-container *ngIf="priceScales">
        <div [ngClass]="show ? 'block opacity-100 pb-3' : 'absolute opacity-0 -z-10'" class="flex flex-col px-5 h-auto gap-y-3 justify-around transition-opacity duration-75">
            
            <div *ngFor="let price of priceScales | keyvalue" 
                (click)="selectPriceScale(price.value.key)" 
                [ngClass]="{'text-secondary': price.value.active, 'opacity-100': show }"  
                class="text-darkgray opacity-0 duration-200 transition-opacity delay-150 ease-in flex justify-between text-xs">
                <p>
                    {{price.value.name | titlecase}} 
                </p>
                
                <span class="text-xs text-darkgray/60">
                    from {{price.value.bestPrice | currency:'GBP'}}
                </span>
            </div>

        </div>
    </ng-container>
</div>