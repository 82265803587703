import { MapViewerEventObject, MapViewerLabelObject, MapViewerNode } from '@3ddv/dvm-internal';
import { MapViewerComponent, MapViewerEvent, NavigableMinimapComponent, Viewer3dComponent } from '@3ddv/ngx-dvm-internal';
import { CurrencyPipe } from '@angular/common';
import { AfterViewInit, Component, Input, NgZone, OnDestroy, OnInit, Signal, ViewChild, computed, inject } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { DvmService } from 'src/app/core/services/dvm.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { ModalService } from 'src/app/core/services/modal.service';
import { AssociationCollection, AssociationComplete } from 'src/app/shared/models/association.model';
import { SeatBuyerType, SeatCollection, SeatData, SeatExchangeData, SeatsHoldCodes } from 'src/app/shared/models/availabilty/seat.model';
import { PriceScaleCollection } from 'src/app/shared/models/availabilty/section.model';
import { Cart, SeatCart } from 'src/app/shared/models/cart.model';
import { Event } from 'src/app/shared/models/event.model';
import { User } from 'src/app/shared/models/user.model';
import { cartHelpers } from 'src/app/utils/helpers/cart.helper';
import { AvailabilityService } from 'src/app/core/services/availability.service';
import { Package } from 'src/app/shared/models/package.model';
import { HoldCodes } from 'src/app/shared/models/exchange/hold-code.modal';
import { ExchangeService } from 'src/app/core/services/override/availability/exchange.service';
import { familyEnclosureValidation } from 'src/app/utils/helpers/family-enclosure.helper';
import { CheckoutService } from 'src/app/core/services/checkout.service';
import { SaleTransaction } from 'src/app/shared/models/transactions/sale-transaction.model';
import { ExchangeItem } from 'src/app/shared/models/checkout.model';
const { formatSeatCustomerCart, formatExchangeCart } = cartHelpers();
const { initFamilyEnclosureValidator ,hasSeatsOnFamilyArea, validateFamilyEnclosure } = familyEnclosureValidation();

@Component({
  selector: 'app-select-seats',
  templateUrl: './select-seats.component.html',
  styleUrl: './select-seats.component.css',
  providers: [ CurrencyPipe ]
})
export class SelectSeatsComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input({required: true})
  entity!:       Signal<Event | Package | null>;

  @Input({required: true})
  associations!: Signal<AssociationCollection | null>

  @Input({required: true})
  availability!: Signal<PriceScaleCollection>;

  @Input({required: true})
  type!:        'event' | 'package' | 'exchange';

  @ViewChild('viewer', {static: false})
  viewer!: MapViewerComponent;

  @ViewChild('minimap', {static: false})
  minimap!: NavigableMinimapComponent;

  @ViewChild('viewer3d', {static: false})
  viewer3d!: Viewer3dComponent;

  constructor(
    private availabilityService:  AvailabilityService< Event | Package , PriceScaleCollection | SeatCollection | string[] >,
    private checkoutService:      CheckoutService<SaleTransaction | any>
  ){}

  /**
   * SERVICES
   */
  public dvm:                     DvmService             = inject(DvmService);                           // DVM Service
  public modalService:            ModalService           = inject(ModalService);                         // Modal Service
  private ngZone:                 NgZone                 = inject(NgZone);                               // NgZone
  private router:                 Router                 = inject(Router);                               // Router
  private auth:                   AuthService            = inject(AuthService);                          // Auth Service
  private currency:               CurrencyPipe           = inject(CurrencyPipe);                         // Currency Pipe
  private params:                 Params                 = inject(ActivatedRoute).snapshot.queryParams;  // Query Params

  /**
   * STATE
   */
  protected event:                Signal<Event|undefined>       = computed(()=> this.type === 'event' ? this.entity() as Event : undefined); // Evento
  protected package:              Signal<Package|undefined>     = computed(()=> this.type === 'package' ? this.entity() as Package : undefined); // Paquete
  protected associationData:      Signal<AssociationComplete[]> = computed(()=> Object.values(this.associations()!) as AssociationComplete[]); // Asociaciones del usuario

  protected customerCart:         SeatCart                = {};      // Customer Cart
  protected checkoutCart:         Cart                    = {};      // Checkout Cart
  protected sectionSelected:      MapViewerNode | null    = null;    // Seccion Seleccionada por el usuario
  protected showTopBar:           boolean                 = false;   // Mostrar TopBar
  protected showMinimap:          boolean                 = false;   // Mostrar Minimap
  protected showViewer3d:         boolean                 = false;   // Mostrar Viewer3d
  protected readonly subs:        Subscription[]          = [];      // Subscripciones
  protected seatAvailability!:    SeatCollection;                    // Disponibilidad de Asientos
  protected userSelected!:        AssociationComplete;               // Usuario Seleccionado

  /**
   * EXCHANGE
   */
  protected isExchange:           boolean                 = false;   // Tipologia de la vista
  protected showLegend:           boolean                 = false;   // Mostrar Leyenda en el DOM (si es Exchange y estamos en vista de asientos)
  protected activeLegend:         boolean                 = false;   // Estado de la leyenda (desplegada o no)
  protected holdCodes!:           HoldCodes;

  // HOOKS

  ngOnInit(): void {
    this.initComponent();
  }

  ngAfterViewInit(): void {
   this.initDvm();
  }

  // GETTERS

  /**
   * Getter que recorre el carrito y devuelve el precio total.
   */
  public get price(): number {
    const data: any[]  = Object.values(this.checkoutCart);
    let  total: number = 0;

    if( data.length ){
      const buyerTypes: SeatBuyerType[] = data.reduce((acc,val)=>((acc.push(...Object.values(val))),acc),[]);
      const totalPrice: number          = buyerTypes.reduce((acc,val)=>(acc += val.price),0);

      total = totalPrice;
    }

    return total;
  }

  /**
   * ### totalPrice
   * Devuelve el precio total del carrito formateado.
   */
  public get totalPrice(): string {
    return 'Subtotal: ' + this.currency.transform( this.price, 'GBP');
  }

  /**
   * ### customerSeats
   * Devuelve los asientos del carrito del usuario.
   */
  public get customerSeats(): string[] {
    const customerSeats  = Object.values(this.customerCart);

    return customerSeats.reduce((acc,val)=>((acc.push(...Object.keys(val))),acc),[] as string[]);
  }

  /**
   * ### cartSeats
   * Devuelve los asientos del carrito.
   */
  public get cartSeats(): string[] {
    if (!this.isExchange) {
      return Object.values(this.checkoutCart).flatMap(val => Object.keys(val));
    } else {
      return Object.keys(this.checkoutCart).flatMap(val => Object.keys(this.checkoutCart[val as any]));
    }
  }

  /**
   * ### cartsAreEqual
   * Devuelve true si los carritos son iguales, false si no.
   */
  public get cartsAreEqual(): boolean {

    // Si es Exchange, salimos
    if(this.isExchange){
      return this.price === 0 ? false : true;
    }

    // Si ambos arrays están vacios, salimos, si no continuamos
    if(this.customerSeats.length === 0 && this.cartSeats.length === 0){
      return false
    }

    // Verificamos que en ambos arrays estan las mismas claves
    for(let key of this.customerSeats){
      if(!this.cartSeats.includes(key)){
        return false;
      }
    }

    return true;
  }

  // METHODS

  /**
   * ### Init Component
   *
   * Inicializa el componente. Inicializa el carrito y el validador de familiy Enclosure.
   * Si es Exchange, inicializa el modo Exchange.
   */
  public initComponent(): void {
    // Instanciamos los carritos ( customerCart y checkoutCart )
    this.initCart();

    // Determinamos si estamos en modo Exchange
    this.isExchange = this.type === 'exchange' ? true : false;

    // Si es Exchange, inicializamos el modo Exchange
    this.isExchange ? this.initExchangeMode() : false;

    // Inicializamos el validador de Family Enclosure
    initFamilyEnclosureValidator(this.isExchange);
  }

  /**
   * ### changeSelectedUser
   * Cambia el usuario seleccionado.
   * @param user
   */
  public changeSelectedUser(user: AssociationComplete): void {
    this.userSelected = user;
  }

  public userBuyerType(birthday: Date): string {
    const exchangeService: ExchangeService = this.availabilityService as ExchangeService;
    return exchangeService.getClientBuyerType(birthday);
  }

  /**
   * ### backToSectionView
   * Vuelve a la vista de secciones. (Reset view)
   */
  public backToSectionView(): void {
    this.dvm.changeMap('blockmap', this.availability(), true).then(()=>{

      // Reset View
      this.showMinimap  = false;
      this.showTopBar   = false;
      this.showViewer3d = false;
      this.showLegend   = false;

      // Persistir selección de sección
      this.viewer.service.select(this.sectionSelected!.id);

    })
  }

  /**
   * ### openSeatView
   * Abre la vista de asientos en 3D.
   * @param id
   */
  public openSeat3DView(id: string){
    this.dvm.load3dView(id)
  }

  /**
   * ### updateCart
   * Actualiza el carrito del usuario.
   * @param userId
   * @param cart
   */
  protected updateCart(userId: number , cart: any){
    this.checkoutCart[userId] = cart;
  }

  /**
   * ### addSeatToCustomerCart
   * Añade un asiento al carrito del usuario.
   * @param item
   * @param customerId
   */
  protected addSeatToCustomerCart(item: any, customerId: number): void {
    const [key, value]: any = Object.entries(item)[0];

    this.customerCart[customerId][key] = value;
  }

  /**
   * ### addSeatToCustomerCartExchange
   *
   * ##### Método de Exchange
   *
   * Añade un asiento al customer cart y al checkout cart en modo Exchange.
   *
   * El customer cart tiene los hold codes como llave y alberga los asientos en un array de asientos asignados.
   * El checkout cart, tiene el id del asiento como llave y el precio del asiento como valor.
   *
   * Para empezar, comprobamos si el asiento ya está en el customer cart del usuario seleccionado, si no es asi,
   * creamos un nuevo objeto en el customer cart con el buyerType del asiento y un array de asientos asignados.
   * Posteriormente, añadimos el asiento al array de asientos asignados.
   *
   * Después añadimos el asiento al checkout cart del usuario seleccionado.
   *
   * @example Customer Cart
   * {
   *   "20000062": {
   *     "1504": {
   *       "seats_assigned": ["S_ELS4-S-71"],
   *       "buyer_type": 1504,
   *       "price": {
   *         "name": "EXCHANGE NON-AMBULANT ADULT",
   *         "price": 44,
   *         "id": 1504
   *       }
   *     },
   *     "1510": ...
   *   },
   *   "20199252": {
   *     "1503": ...
   *     "1512": ...
   *   }
   * }
   *
   * @example Checkout Cart
   *
   * {
    "20000062": {
        "1001-120186": {
            "name": "EXCHANGE NON-AMBULANT ADULT"
            "price": 44,
            "id": 1504
        }
    },
    "20199252": {
        "1001-120334": {
            "price": 15.5,
            ...
        }
    }
  }
  */
  protected addSeatToCustomerCartExchange(seatPrice: SeatExchangeData['prices'][0], node: MapViewerNode): void {
    // Customer Cart
    if(!this.customerCart[this.userSelected.associate_id].hasOwnProperty(seatPrice.id)){

      this.customerCart[this.userSelected.associate_id][seatPrice.id] = {
        seats_assigned: [],
        seats_assigned_og: [],
        buyer_type:     seatPrice.id,
        price:          seatPrice,
      }

    }

    this.customerCart[this.userSelected.associate_id][seatPrice.id].seats_assigned!.push(node.id);
    this.customerCart[this.userSelected.associate_id][seatPrice.id].seats_assigned_og?.push(node.original_id)

    // Checkout Cart
    if(!this.checkoutCart[this.userSelected.associate_id]){
      this.checkoutCart[this.userSelected.associate_id] = {}
    }

    this.checkoutCart[this.userSelected.associate_id][node.id] = seatPrice;

    // Seteamos Grupo (usuario) al asiento seleccionado
    this.dvm.setGroup(node.id, this.userSelected.associate_id.toString());
  }

  /**
   * ### removeFromCart
   * Elimina un asiento del carrito. Si el parametro manualUnselect es true, deselecciona el asiento del DVM.
   * @param id
   * @param manualUnselect
   */
  protected removeFromCart(id: string, manualUnselect = false): void {

    for(let customerId in this.customerCart){
      for(let seatId in this.customerCart[customerId]){
        if(this.customerCart[customerId].hasOwnProperty(id)) delete this.customerCart[customerId][id];
      }
    }

    for(let customerId in this.checkoutCart){
      for(let seatId in this.checkoutCart[customerId]){
        if(this.checkoutCart[customerId].hasOwnProperty(id)) delete this.checkoutCart[customerId][id];
      }
    }

    if(manualUnselect){
      this.dvm.unselect(id);
    }
  }

  /**
   * ### removeFromCartExchange
   *
   * ##### Método de Exchange
   *
   * Elimina un asiento del carrito en modo Exchange. Si el asiento tiene más de un precio, comprueba si el asiento tiene más de un precio y elimina el precio correcto.
   * Si el asiento no tiene más de un precio, elimina el precio del asiento.
   * Si el array de asientos asignados está vacío, elimina el Hold Code del carrito del usuario.
   * Finalmente, deselecciona el asiento del DVM y elimina el asiento del grupo asociado al usuario y del carrito de checkout.
   *
   * @param {MapViewerNode} node
   * @returns {void} void
   */
  protected removeFromCartExchange(node: MapViewerNode): void {

    // Si no pertenece al usuario seleccionado, no dejamos deseleccionar.
    if(node.groups.indexOf(this.userSelected.associate_id.toString()) === -1){
      this.dvm.select(node.id);
      this.modalService.createInformationModal('Error','You are not allowed to unselect this seat').subscribe();
      return;
    }

    // Instanciamos servicio de exchange y conseguimos los precios por HoldCode del asiento
    const exchangeService: ExchangeService   = this.availabilityService as ExchangeService;
    const seatHold:        SeatExchangeData  = exchangeService.seatHoldCodes[node.tag!][node.id.split('-')[1]];

    // Instanciamos varible que incluirá el price del asiento
    let price!: SeatExchangeData['prices'][0];

    // Si el asiento tiene más de un precio, comprobamos si el asiento tiene más de un precio y seleccionamos el precio seleccionado.
    if(seatHold.prices.length > 1){
      seatHold.prices.forEach(seatPrice => {

        const seatData: SeatData = this.customerCart[this.userSelected.associate_id][seatPrice.id];

        if(seatData && seatData.seats_assigned!.includes(node.id)){
          price = seatData.price!;
        }

      })
    }else{
      price = seatHold.prices[0]
    }

    // Instanciamos el carrito del usuario seleccionado usando el id del Hold Code
    // ademas, obtenemos la posición del asiento en el array de asientos asignados y lo eliminamos.
    const buyerTypeCart: SeatData = this.customerCart[this.userSelected.associate_id][price.id];
    const seatIndex:     number   = buyerTypeCart.seats_assigned!.indexOf(node.id);

    // Eliminamos el asiento del array de asientos asignados
    if(seatIndex !== -1){
      buyerTypeCart.seats_assigned!.splice(seatIndex,1);
      buyerTypeCart.seats_assigned_og?.splice(seatIndex,1);
    }

    // Si el array de asientos asignados está vacío, eliminamos el Hold Code del carrito del usuario.
    if(buyerTypeCart.seats_assigned!.length === 0){
      delete this.customerCart[this.userSelected.associate_id][price.id];
    }

    // Finalmente, deseleccionamos el asiento del DVM y eliminamos el asiento del grupo asociado al usuario y del carrito de checkout.
    this.dvm.unselect(node.id);
    this.dvm.removeGroup(node.id, this.userSelected.associate_id.toString());

    delete this.checkoutCart[this.userSelected.associate_id][node.id];
  }

  /**
   * ### launchModal
   * Lanza un modal de confirmación. Si el usuario confirma, inicia el proceso de checkout.
   */
  protected launchModal(): void {
    const title:   string = `<span class="dark:text-secondary">Continue ?</span>`;
    const content: string = `
    <div class="text-left">
      <p class="text-base dark:text-gray-500">
        By clicking continue, your selection will be place on hold and you will be redirected to the checkout page.
      <p>
      <br>
      <p class="dark:text-secondary"><b>NOTE</b></p>
      <p class="text-red-500 text-sm mt-2 font-medium">
        JUVENILES UNDER THE AGE OF 16 WILL NOT BE PERMITTED ENTRY TO THE GROUND UNLESS ACCOMPANIED BY A PERSON OVER 18 YEARS
      </p>
      <p class="text-sm text-gray-500 mt-2 font-medium">
      AT STAMFORD BRIDGE, THE ENTIRE SHED END AND THE MATTHEW HARDING LOWER TIER ARE SAFE STANDING AREAS.
      </p>
    </div>
    `;

    this.modalService.createConfirmationModal(title,content).subscribe(resultado => resultado.isConfirmed ? (!this.isExchange ? this.startCheckout() : this.startExchangeCheckout()) : false)
  }

  /**
   * ### launchOptionsModal
   *
   * ##### Método de Exchange
   *
   * Este método se llama al hacer una selección en un asiento que tiene más de un precio.
   * Lanza un modal de opciones con los precios disponibles para el asiento seleccionado y retorna
   * el index del precio seleccionado. Posteriormente, se añade el asiento al carrito del usuario.
   *
   * En Sweet Alert, para crear opciones radio tenemos que pasar un objeto en el que
   * la llave es el valor que devuelve el modal y la propiedad es el texto que se muestra.
   * @example
   * options = {0: 'Adult (10£)', 1: 'Child (5£)'}
   *
   */
  protected launchOptionsModal(seat: SeatExchangeData, node: MapViewerNode):void {
    const options: {[x:number]: string} = {};

    seat.prices.forEach((price, index) => {
      const text: string = `${price.name} (${this.currency.transform(price.price, 'GBP')})`;
      options[index] = text;
    });

    this.modalService.createOptionsModal('Select a price', options).subscribe(value => {
      this.ngZone.run(()=> this.addSeatToCustomerCartExchange(seat.prices[value.value as number], node));
    });

  }

  /**
   * ### toggleLegend
   *
   * ##### Método de Exchange
   *
   * Muestra u oculta la leyenda en el DOM.
   *
   * @param status
   */
  protected toggleLegend(status: boolean): void {
    this.activeLegend = status;
  }

  /**
   * ### Init Cart
   * Inicializa el carrito para cada asociado. Comprueba que el user logueado esté seleccionado, si no, selecciona el primer usuario de la lista de seleccionados.
   * Se rellena el objeto customerCart con las asociaciones y se selecciona el usuario logueado.
   */
  private initCart(): void {

    // Get Associations
    const associations: AssociationComplete[] = this.associationData().sort().reverse();

    // Set Cart
    associations.forEach(customer => this.customerCart[customer.associate_id] = {});

    // Get User
    this.auth.getUser().then( user$ => {
      const user = user$ as User;

      let isUserSelected: AssociationComplete[] = associations.filter(customer => customer.associate_id === user.tdc_info.id);

      // If user logged is selected, set as selected, if not, set first user as selected
      if(isUserSelected.length){
        let key: number = associations.indexOf(isUserSelected[0])
        this.userSelected = associations[key];
      }else{
        this.userSelected = associations[0]
      }

    });
  }

  /**
   * ### Init DVM
   * Inicializa el DVM con el viewer, viewer3d, el evento, la disponibilidad y el id del minimapa.
   * Guarda la subscripción del evento click en el array de subscripciones.
   */
  private initDvm(): void {
    const viewer:       MapViewerComponent   = this.viewer,
          viewer3d:     Viewer3dComponent    = this.viewer3d,
          event:        string               = this.entity()?.venue!,
          availability: PriceScaleCollection = this.availability();

    //Init DVM
    this.dvm.initializeDvm(viewer, viewer3d, event, availability, 'navigable_minimap', true);

    // Subscribe Click
    this.subs.push(this.viewer.service.getObservable('click').subscribe(obj=> this.onMapClick(obj)));
  }

  /**
   * ### Init Exchange Mode
   *
   * ##### Método de Exchange
   *
   * Inicializa el modo de Exchange. Obtiene los hold codes del servicio de Exchange y los guarda en la variable holdCodes.
   * Además, setea los estilos personalizados en el DVM.
   */
  private initExchangeMode(): void {

    const exchangeService: ExchangeService = this.availabilityService as ExchangeService;

    this.holdCodes = exchangeService.holdCodes;

    this.dvm.setCustomStyles(this.holdCodes);
  }

  /**
   * ### On Map Click
   * Maneja el evento click del DVM. Si el nodo no está disponible o no tiene estado, no hace nada.
   * Si el estado del nodo es 'available' o 'selected', selecciona o deselecciona el nodo.
   * Si el nodo es de tipo 'section', llama a sectionClick. Si es de tipo 'seat', llama a seatClick.
   * @param {MapViewerEvent<MapViewerEventObject>} obj DVM Node
   * @returns
   */
  private onMapClick(obj: MapViewerEvent<MapViewerEventObject>): void {
    const node = obj.event.nodes[0];

    if(!node || node.state === 'unavailable' || node.state === null){
      return
    }

    switch(node.state){
      case 'available':
        this.dvm.select(node.id);
        break;
      case 'selected':
        this.dvm.unselect(node.id);
        break;
    }

    switch(node.type){
      case 'section':
        this.sectionClick(node);
        break;
      case 'seat':
        !this.isExchange ?
          this.seatClick(node) :
          this.seatClickExchange(node);
        break;
    }

  }

  /**
   * ### Section Click
   * Maneja el evento click de una sección. Si hay una sección seleccionada, la deselecciona.
   * Si no, selecciona la sección y obtiene la disponibilidad de asientos de esa sección.
   *
   * La disponibilidad, en Events y Packages, es un objeto que incluye como sus keys las ids de los asientos y contienen
   * el objeto con la data del asiento.
   *
   * Si es Exchange, el servicio especializado devuelve un array de strings con las ids de los asientos. Ya que en este caso, consultamos de otro
   * modo los asientos seleccionados.
   *
   * @param {MapViewerNode} node
   */
  private sectionClick(node: MapViewerNode): void {

    this.sectionSelected ? this.dvm.unselect(this.sectionSelected.id) : false;
    this.sectionSelected = node;

    this.availabilityService.getSectionAvailability(this.entity()?.id!, node.id).subscribe(( availability: PriceScaleCollection | SeatCollection | string[] ) => {

      let seatsIds: string[] = [];

      if(!this.isExchange){
        this.seatAvailability = availability as SeatCollection;
        seatsIds              = Object.keys(this.seatAvailability)
      }else{
        seatsIds              = availability as string[];
      }

      this.dvm.changeMap(node.id, seatsIds, true).then(()=> {

        if(this.isExchange){
          this.tagExchangeSeats(seatsIds as string[]);
        }

        this.enableSeatView();
      });
    })

  }

  /**
   * ### Seat Click
   * Maneja el evento click de un asiento. Si el asiento está seleccionado, lo elimina del carrito.
   * Si no, lo añade al carrito.
   * @param {MapViewerNode} node
   */
  private seatClick(node: MapViewerNode): void {

    let seatData: SeatData = this.seatAvailability[node.id],
        cartItem: SeatCollection = {[node.id]:seatData};

    if(node.original_id){
      cartItem[node.id].originalId = node.original_id;
      cartItem[node.id].section  = node.id.split('-')[0];
      cartItem[node.id].section  = node.id.split('-')[0];
    }

    switch (node.state){
      case 'selected':
        this.ngZone.run(()=> this.addSeatToCustomerCart(cartItem, this.userSelected.associate_id));
      break;
      case 'available':
        this.ngZone.run(()=>this.removeFromCart(node.id));
      break;
    }

  }

  /**
   * ### Seat Click Exchange
   *
   * ##### Método de Exchange
   *
   * Maneja el evento click de un asiento en modo Exchange. Si el asiento está seleccionado, lo elimina del carrito.
   * Si no, lo añade al carrito.
   *
   * Para ello, hace comprobaciones de si el usuario puede seleccionar el asiento o no mediante el buyerType del asiento y el buyerType del usuario.
   * Si el buyerType del asiento no coincide con ADULT ni con el buyerType del usuario, no permite seleccionar el asiento.
   * Finalmente, si el asiento tiene más de un precio, lanza un modal con las opciones de precios, si no, añade el precio por defecto.
   *
   * Sin embargo, si el asiento está ya seleccionado, lo elimina del carrito. Como esta función se llama después de haber cambiado el estado del nodo,
   * se comprueba si el estado es 'selected' o 'available' y se actúa en consecuencia. Es decir, si es selected se añade al carrito, si es available se elimina.
   *
   * @param node
   * @returns
   */
  private seatClickExchange(node: MapViewerNode): void {

    // Inicializamos el servicio de Exchange
    const exchangeService:  ExchangeService = this.availabilityService as ExchangeService;

    // Obtenemos el seatId y el seatData
    const seatId: string            = node.id.split('-')[1]; // Como llega "section-seatId", cogemos solo el seatId
    const seat:   SeatExchangeData  = exchangeService.seatHoldCodes[node.tag!][seatId]

    // Obtenemos el cumpleaños del usuario seleccionado y su buyerType
    const userBirthday:   Date   = this.userSelected.tdc_info.birthday;
    const userBuyerType:  string = exchangeService.getClientBuyerType(userBirthday);

    // Obtenemos el buyerType del asiento
    const seatBuyerType: string = this.holdCodes[node.tag!].buyer_type as string;

    // Comprobamos si el buyerType no coincide con ADULT ni con el tag del asiento
    if(seatBuyerType !== 'ADULT' &&
       seatBuyerType !== userBuyerType &&
       node.state === 'selected')
      {
      this.modalService.createInformationModal('Error','You are not allowed to select this seat').subscribe(()=> this.dvm.unselect(node.id));
      return;
    }


    switch (node.state){
      case 'selected':
        /**
         * Comprobamos si el asiento tiene más de un precio.
         * Si lo tiene, creamos un objeto con las opciones y lanzamos un modal.
         * Si no, se establece el unico que tiene por defecto.
         * Finalmente en ambos casos se lanza el switch que determina que debe pasar en base
         * al estdo del nodo.
          */
        if(seat.prices!.length > 1){
          this.launchOptionsModal(seat, node);
        }else{
          this.ngZone.run(()=> this.addSeatToCustomerCartExchange(seat.prices[0], node));
        }
      break;
      case 'available':
        this.ngZone.run(()=> this.removeFromCartExchange(node));
      break;
    }

  }

  /**
   * ### Enable Seat View
   * Habilita o deshabilita la vista de asientos. Muestra el topbar, el minimapa y el viewer3d.
   */
  private enableSeatView(): void {

    this.showTopBar   = true;
    this.showMinimap  = true;
    this.showViewer3d = true;
    this.showLegend   = true;
    
    // Modo Clasico (Eventos y Paquetes)
    if(!this.isExchange && this.customerSeats.length){
      this.dvm.select(this.customerSeats);
    }

    // Modo Exchange
    if(this.isExchange && this.cartSeats.length){
      this.dvm.select(this.cartSeats);
    }

  }

  /**
   * Método que se lanza solo en modo Exchange al cambiar la vista a asientos con la disponibilidad
   * de la sección.
   *
   * Este método se encarga de mapear los hold codes a los asientos y añadir los tags y labels correspondientes.
   * Para ello castea el servicio de disponibilidad a ExchangeService y obtiene los hold codes.
   *
   * Finalmente, mapea los hold codes a los asientos y añade los tags y labels correspondientes.
   *
   * @param {string[]} availability
   */
  private tagExchangeSeats(availability: string[]): void {

    const exchangeService: ExchangeService   = this.availabilityService as ExchangeService, // Servicio de Exchange
          seatHoldCodes:   SeatsHoldCodes    = exchangeService.seatHoldCodes,               // Hold Codes de los asientos (Respuesta Original sin mapear desde el servicio)
          labels:          {[x:string]: MapViewerLabelObject} = {};                              // Labels de los asientos

    // Mapeamos los hold codes a los asientos y añadimos los tags
    Object.entries(seatHoldCodes).forEach(item => {

      const [key, value]          = item,
            label:      string    = this.holdCodes[key].label,                                  // Label del Hold Code
            sectionId:  string    = availability[0].split('-')[0],                              // Sección de los asientos
            seatsId:    string[]  = Object.keys(value).map(key=> sectionId + '-' + key);        // Asientos formateados

      // Seteamos los tags en el DVM
      this.dvm.setTags(seatsId, key);

      // Añadimos el label a los asientos
      for(const key of seatsId){
        labels[key] = {text: label, size: 1};
      }

    });

    // Seteamos los labels en el DVM
    Object.keys(labels).forEach(key => this.dvm.setLabel(key, labels[key]));
  }

  /**
   * ### Start Checkout
   * Inicia el proceso de checkout. Formatea el carrito y llama al servicio de checkout.
   * Si hay un error, muestra un modal de error.
   * Si no, navega a la página de checkout. Además, si hay un param transaction lo añade como transaccion relacionada.
   */
  private startCheckout(): void {

    // const eventId:             number  = this.entity()?.id!;
    const friendsFamily:       any     = [];
    const hasSeatOnFamilyArea: boolean = hasSeatsOnFamilyArea(this.customerCart, this.isExchange);

    let isValidTransaction:    boolean = true;


    if(hasSeatOnFamilyArea){
      isValidTransaction = validateFamilyEnclosure(this.checkoutCart, this.isExchange);
    }

    if(!isValidTransaction){
      this.modalService.createInformationModal('System Message','The rules of Family enclosure are not met.').subscribe();
      return;
    }

    // Formateamos el carrito por cada usuario
    for(let customer in this.checkoutCart){

      const formattedCart = {
        customer: parseInt(customer),
        seats: formatSeatCustomerCart(this.checkoutCart[customer]),
      };

      friendsFamily.push(formattedCart);

    }

    // Creamos el carrito final
    let finalCart: any = {
      friends_family_accounts: friendsFamily,
    }

    // Si es un evento, añadimos el id del evento, si es un paquete, añadimos el id del paquete
    switch(this.type){
      case 'event':
        finalCart.event = this.event()?.id!;
      break;
      case 'package':
        let priceScaleId: string = Object.keys(friendsFamily[0].seats)[0];

        finalCart.package = this.package()?.id!;
        finalCart.price_scale = priceScaleId;
      break;
    }

    // Si hay una transacción relacionada, la añadimos
    if(this.params['transaction']){
      finalCart.from_transaction = this.params['transaction'];
    }

    // Iniciamos el checkout y manejamos el resultado
    this.checkoutService.initCheckout(finalCart, 'seat_selection').subscribe({
      next:  (v) => this.nextStep(v),
      error: (e) => e.error && e.error.message ?
        this.modalService.createErrorModal(e.error.message).subscribe() :
        this.modalService.createErrorModal().subscribe()
    })

  }

  private startExchangeCheckout(): void {
    const actualCart:          SeatCart        = JSON.parse(JSON.stringify(this.customerCart));
    const eventId:             number          = this.entity()?.id!;
    const hasSeatOnFamilyArea: boolean         = hasSeatsOnFamilyArea(actualCart, this.isExchange);
    let   isValidTransaction:  boolean         = true;

    if(hasSeatOnFamilyArea){
      isValidTransaction = validateFamilyEnclosure(actualCart, this.isExchange);
    }

    if(!isValidTransaction){
      this.modalService.createInformationModal('System Message','The rules of Family enclosure are not met.').subscribe();
      return;
    }

    let friendsFamily: SeatCart[] = formatExchangeCart(actualCart);

    const finalCart: ExchangeItem = {
      event: eventId,
      friends_family_accounts: friendsFamily,
    }

    this.checkoutService.initCheckout(finalCart).subscribe({
      next:  (v) => this.nextStep(v),
      error: (e) => e.error && e.error.message ?
        this.modalService.createErrorModal(e.error.message).subscribe() :
        this.modalService.createErrorModal().subscribe()
    })

  }

  /**
   * ### Next Step
   * Maneja el resultado del checkout. Si hay una transacción relacionada, la añade como parametro a la url.
   * Navega a la página de checkout.
   * @param {any} response
   */
  private nextStep(response: any): void {
    const relatedTransactionId: string | undefined = this.params['transaction'];
    const params = {
      transaction: relatedTransactionId ? relatedTransactionId : response.id,
      type: this.type === 'event' ? 'sale' : this.type
    }

    this.auth.getUser(true).then(()=> {
      switch(this.type){

        // Eventos
        case 'event':
          this.router.navigate(['buy-tickets/checkout'], {queryParams: params} )
        break;

        // Packages
        case 'package':
          this.router.navigate(['buy-packages/checkout'], {queryParams: params} )
        break;

        // Exchange
        case 'exchange':
          this.router.navigate(['exchange/checkout'], {queryParams: params} )
        break;
      }
    });
  }

  /**
   * Unsubscribe from all subscriptions
   */
  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe());
  }
}

