<div class="border flex flex-col rounded-xl overflow-hidden container lg:max-w-6xl xl:max-w-7xl xl:w-[80rem] bg-white dark:bg-slate-900 dark:border-secondary/50">
    <!-- EVENT CARD -->
    <ng-container *ngIf="type === 'event'">
        <!-- EVENT DETAIL -->
         <app-event-detail
            [id]="event!.id" 
            [name]="event!.name" 
            [venue]="event!.venue" 
            [date]="event!.date" 
            [available]="event!.single_ticket_sale_allowed"
            [showButton]="showButton"
            (eventSelected)="selectItem()"
        />
        <!-- EVENT DESCRIPTION -->
        <div *ngIf="showText && event.description" class="bg-gray-50 dark:bg-gray-950/50 text-center py-5 px-4 lg:flex lg:flex-wrap lg:p-10">
            <h4 class="text-secondary mb-3 mt-3 font-medium lg:w-2/12 lg:my-0 lg:text-sm lg:text-left">
                Information
            </h4>
            <p class="text-darkgray text-center text-xs lg:w-10/12 lg:text-left lg:text-sm dark:text-gray-500 prose max-w-none lg:max-w-3xl">
               {{event!.description}}
            </p>
        </div>
    
    </ng-container>

    <!-- PACKAGE CARD -->
    <ng-container *ngIf="type === 'package'">
        <app-package-detail
            [id]="package!.id"
            [name]="package!.name"
            [venue]="package!.venue"
            [available]="package.saleAllowed"
            [showButton]="showButton"
            (packageSelected)="selectItem()"
        />

        <div *ngIf="showText && package.description" class="bg-gray-50 dark:bg-gray-950/50 text-center py-5 px-4 lg:flex lg:flex-wrap lg:p-10">
            <h4 class="text-secondary mb-3 mt-3 font-medium lg:w-2/12 lg:my-0 lg:text-sm lg:text-left">
                Information
            </h4>
            <p class="text-darkgray text-center text-xs lg:w-10/12 lg:text-left lg:text-sm dark:text-gray-500 prose max-w-none lg:max-w-3xl">
               {{package!.description}}
            </p>
        </div>
    </ng-container>
      
    <!-- CONTENT  -->
    <ng-content></ng-content>
</div>