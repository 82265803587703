<div class="dark:bg-main rounded-xl p-3 shadow" [ngClass]="{'bg-main shadow-md shadow-secondary/50': isSelected, ' bg-white': !isSelected}" (click)="changeUser()" >
    
    <!-- USER -->
    <div class="flex justify-between px-1 items-center w-full h-full cursor-pointer" (click)="toggleSelector()">
        <p class="text-secondary dark:text-white font-semibold w-1/2" [ngClass]="{'text-white': isSelected}">
            {{user.tdc_info.first_name}} {{user.tdc_info.last_name}} 
        </p>           
        <span *ngIf="userBuyerType" class="font-main font-medium text-gray-500" [ngClass]="{'text-white': isSelected}">
            {{userBuyerType}}
        </span>
    </div>

    <!-- SEATS NORMAL -->
    <ng-container *ngIf="!isExchange else exchangeSeats">
        <div class="p-3 mt-2 bg-gray-100 rounded-lg" *ngFor="let seat of customerCart | keyvalue">
            <!-- Seat Data -->
            <div class="flex w-full justify-between">
                <!-- Title of Seat -->
                <p class="font-medium text-gray-700 font-main">
                    {{formatSeatName(seat.value.originalId!)}}
                </p>
                <!-- Iconos Visor y Eliminar -->
                <div class="flex gap-x-2">
                    <!-- Visor -->
                    <button class="viewSeat" (click)="openView3d(seat.key); $event.stopPropagation()">
                        <i>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 text-darkgray hover:text-secondary transition-colors">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                                <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z" />
                            </svg>
                        </i>
                    </button>
    
                    <!-- Eliminación -->
                    <button class="eraseSeat" (click)="removeSeat(seat.key); $event.stopPropagation()">
                        <i>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 text-darkgray hover:text-secondary transition-colors">
                                <path stroke-linecap="round" stroke-linejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                            </svg>
                        </i>
                    </button>
                </div>
            </div>
            <!-- Seat Buyer Types -->
            <div class="mt-1">
                <!-- BUYER TYPE LIST -->
                <ng-container *ngIf="!cart.hasOwnProperty(seat.key)">
                    <ul>
                        <li class="only:rounded-[15px_15px_15px_15px] first:rounded-[15px_15px_0_0] border-b p-2 last:border-b-0 last:rounded-[0_0_15px_15px] bg-white transition-colors duration-100 ease-in hover:bg-secondary hover:text-white" 
                            *ngFor="let buyerTypes of seat.value.buyer_types | keyvalue">
                            <div class="cursor-pointer w-full h-full" (click)="selectBuyerType(buyerTypes.value , seat.key)">
                                <p class="text-sm flex justify-between items-baseline">
                                    {{buyerTypes.value.name}}
                                    <span class="text-xs text-current/50">
                                        {{buyerTypes.value.price | currency:'GBP'}}
                                    </span>
                                </p>
                            </div>
                        </li>
                    </ul>
                </ng-container>
               
                <!-- BUYER TYPE SELECTED -->
                <ng-container *ngIf="cart.hasOwnProperty(seat.key)">
                    <div class="bg-secondary text-white cursor-pointer rounded-[15px] p-2"
                     (click)="unselectBuyerType(seat.key)">
                        <p class="text-sm flex justify-between items-baseline">
                            {{cart[seat.key].name}}
                            <span class="text-xs text-current/50">
                                {{cart[seat.key].price | currency:'GBP'}}
                            </span>
                        </p>
                    </div>
                </ng-container>
                
            </div>
        </div>    
    </ng-container>

    <!-- SEATS EXCHANGE -->
    <ng-template #exchangeSeats>
        <div class="pl-1 cursor-default">
            <p *ngIf="exchangeCart.length" class="text-sm" [ngClass]="isSelected ? 'text-white' : 'text-gray-500'">
                Seats:
            </p>
            <div *ngFor="let seat of exchangeCart | keyvalue" [ngClass]="isSelected ? 'text-white' : 'text-secondary'">
                {{formatSeatName(seat.value)}}
            </div>
        </div>
</ng-template>

</div>