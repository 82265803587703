<div class="h-full w-full flex flex-col justify-between xl:justify-start bg-gradient-to-t from-secondary/5 to-white lg:bg-white overflow-x-hidden dark:lg:bg-gray-900 dark:to-gray-900">

    <ng-container *ngIf="entity()">
        <app-back-button>
    
            <!-- SELECT A SEAT TOGGLER -->
            <ng-container *ngIf="entityCanToggleSas()">
                <label class="inline-flex items-center cursor-pointer">
                    <input type="checkbox" class="sr-only peer" [ngModel]="isSelectSeat()" (ngModelChange)="isSelectSeat.set($event)">
                    <div class="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-2 peer-focus:ring-secondary dark:peer-focus:ring-main rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-main"></div>
                    <p class="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300" [ngClass]="{'text-secondary' : isSelectSeat()}">Select Seat</p>
                </label>
            </ng-container>
    
        </app-back-button>
    </ng-container>


    <!-- MAIN COMPONENT -->
    <ng-container *ngIf="entity() && hasAvailability && hasAssociations && type else loader">
        
        <!-- PRICE SCALES -->
        <ng-container *ngIf="!isSelectSeat() && type !== 'exchange' else SelectSeat">
            <app-select-prices
                [type]="type"
                [entity]="entity"
                [associations]="associations"
                [availability]="availability"
            />
        </ng-container>

        <!-- SELECT A SEAT -->
        <ng-template #SelectSeat>
            <app-select-seats  
                [type]="type"
                [entity]="entity" 
                [associations]="associations" 
                [availability]="availability"
            />
        </ng-template>
    
        <!-- LOADER -->
        <app-loader/>

    </ng-container>

    <!-- LOADER -->
    <ng-template #loader>
        <div class="w-screen h-[calc(100dvh-20vh)] flex overflow-hidden items-center justify-center">
            <app-loader class="block"/>
        </div>
    </ng-template>

</div>
