import { MapViewerComponent, MapViewerInitOptions, MapViewerService, Viewer3dComponent, Viewer3dInitOptions} from '@3ddv/ngx-dvm-internal';
import { MapViewerLoadMapOptions, Viewer3dLoadView3dOptions, MapViewerPluginsList, MapViewerLoadObject, MapViewerStyles, MapViewerStylesState, MapViewerInputNodes, MapViewerInputNode, MapViewerLabelObject } from '@3ddv/dvm-internal';
import { Injectable, inject } from '@angular/core';
import { Configuration } from 'src/app/shared/models/configuration.model';
import { APP_CONFIG } from 'src/configuration/configuration';
import { availabilityHelpers } from 'src/app/utils/helpers/availability.helpers';
import { PriceScaleCollection } from 'src/app/shared/models/availabilty/section.model';
import { SeatCollection } from 'src/app/shared/models/availabilty/seat.model';
import { Observable, tap } from 'rxjs';
import { HoldCodes } from 'src/app/shared/models/exchange/hold-code.modal';

const { mergeAllSections } = availabilityHelpers();

@Injectable({
  providedIn: 'root'
})
export class DvmService {

  /**
   * APP CONFIGURATION
   */
  private AppConfiguration:     Configuration = inject(APP_CONFIG); 	
  private viewer!:              MapViewerComponent;
  private viewer3d!:            Viewer3dComponent;

  /**
   * GENERAL CONFIG
   */
  public showViewer!:           boolean;
  public customStyles!:         HoldCodes | null;

  /**
   * INIT CONFIG OPTIONS
   */
  public mapViewerInitOptions:  MapViewerInitOptions     = {};
  public viewer3dInitOptions:   Viewer3dInitOptions      = {};
  
  /**
   * LOAD CONFIG OPTIONS
   */
  public mapViewerLoadOptions:  MapViewerLoadMapOptions   = { venue_id: '', map_id: '' };
  public viewer3dLoadOptions:   Viewer3dLoadView3dOptions = { venue_id: '', view_id:'' };   

  public initializeDvm(
    viewer: MapViewerComponent,
    viewer3d: Viewer3dComponent,
    key: keyof Configuration['venue'], 
    availability: PriceScaleCollection,
    plugin?: MapViewerPluginsList,
    selectSeat?: boolean): void {

      this.viewer   = viewer;
      this.viewer3d = viewer3d;

      const venueData = this.AppConfiguration.venue[key];
    
      if(plugin){
        this.setPlugin(plugin);
      }

      if(venueData){
        this.setupViewers(venueData, availability, selectSeat);
      }else{
        this.viewer.service.close()
       return;
    }
  }

  public disableDvm(): void {
    this.showViewer = false;
  }

  public select(id: string | string[]): void{
    this.viewer.service.select(id);
  }

  public unselect(id: string): void {
    this.viewer.service.unselect(id);
  }

  public unselectAll(): void {
    this.viewer.service.unselectAll();
  }

  public resetSelectionAndPosition(): void {
    this.unselectAll();
    this.viewer.service.goTo([0,0], 1.1);
  }

  public changeMap(
    id:           string, 
    availability: PriceScaleCollection | SeatCollection | string[], 
    selectSeat:   boolean = false
    ): Promise<boolean> {
    
    return new Promise<boolean>((resolve)=>{
      const options = this.mapViewerLoadOptions;
    
      options.map_id   = id
      options.venue_id = this.viewer.service.getVenueId()!;
  
     this.loadMap(options).subscribe(()=>{
        this.setMapAvailability(availability, selectSeat);
      
        if(selectSeat){
          this.load3dView(id);
        }

        resolve(true);
      })
    })
   
  }
  
  public loadMap(loadOptions: MapViewerLoadMapOptions): Observable<MapViewerLoadObject>{
    return this.viewer.service.loadMap(loadOptions).pipe(tap(()=> this.setStyles()));
  }

  public load3dView(id: string): void {
    this.viewer3dLoadOptions.view_id = id;
    this.viewer3d.service.loadView3d(this.viewer3dLoadOptions).subscribe()
  }

  public setCustomStyles(styles: HoldCodes): void {
    this.customStyles = styles;
  }

  public setTags(elements: MapViewerInputNodes, tag: string): void {
    this.viewer.service.setNodesTag(elements, tag);
  }

  public setLabel(node: MapViewerInputNode, label: MapViewerLabelObject): void {
    this.viewer.service.updateLabel(node, label);
  }

  public setGroup(nodeId: string, group: string): void {
    this.viewer.service.addNodesToGroup([nodeId], group);
  }

  public removeGroup(nodeId: string, group: string): void {
    this.viewer.service.removeNodesFromGroup([nodeId], group);
  }

  protected setPlugin(plugin: MapViewerPluginsList): void {
    this.mapViewerInitOptions.plugins = [plugin];
  }

  protected setStyles(): any {
    const styles = this.viewer.service.getStyles();
    
    this.setViewerStyles(styles, this.customStyles)
  }

  private setupViewers(
      data: Configuration['venue'][''], 
      availability: PriceScaleCollection, 
      selectSeat: boolean = false): void {
    
    //Si el Venue tiene Client lo añadimos, en caso contrario devolvemos el objeto vacio (por si anteriormente se habia instanciado con client)
    data.client ? this.mapViewerInitOptions.client_id = data.client : this.mapViewerInitOptions = {};
    
    //Agregamos las propiedades LoadOptions 
    this.mapViewerLoadOptions.map_id   = data.mapID;
    this.mapViewerLoadOptions.venue_id = data.venueID;
    this.viewer3dLoadOptions.venue_id  = data.venueID;
    
    this.showViewer = true; 
    
    //Mostramos DVM
    if(!this.viewer.service.isInitialized()){
      this.viewer.service.initialize(this.mapViewerInitOptions).subscribe(()=>{
        const viewer = this.viewer.service;
        
        viewer.flags.automatic_selection = false;
        viewer.flags.fixed_aspect_ratio  = false;
        
        //Cargamos Opciones  
        this.loadMap(this.mapViewerLoadOptions).subscribe((obj: MapViewerLoadObject)=>{
          this.setMapAvailability(availability, selectSeat) 
        });
        
        this.viewer3d.service.initialize(this.viewer3dInitOptions).subscribe();
      });
    }else{
      this.viewer.service.loadMap(this.mapViewerLoadOptions).subscribe((obj)=>{
        this.setMapAvailability(availability, selectSeat) 
      });
      this.viewer3d.service.initialize(this.viewer3dInitOptions).subscribe();
    }
      
  }

  private setMapAvailability(
    availability: PriceScaleCollection| SeatCollection | string[], 
    selectSeat:   boolean
  ): void {

    const viewer: MapViewerService = this.viewer.service;
    let   nodes!: string[];

    if(Array.isArray(availability)){
      nodes = availability as string[];
    }else{
      nodes = selectSeat ? 
        Object.keys(availability as SeatCollection) : 
        mergeAllSections(availability as PriceScaleCollection);
    }
    viewer.getTypesList().forEach((type) => viewer.setAvailability(type, nodes));
  }
  
  private setViewerStyles( styles: MapViewerStyles, customStyles?: HoldCodes | null ): void {
    
    // THEME COLORS
    const dvmTheme = this.AppConfiguration.theme.dvm;

    // SECTION STYLES
    if(styles[0] && styles[0]['section']){

      const sectionAvailable: MapViewerStylesState = styles[0]['section']['available']! as MapViewerStylesState,
            sectionSelected:  MapViewerStylesState = styles[0]['section']['selected']! as MapViewerStylesState;

            // AVAILABLE NORMAL
            sectionAvailable.normal.none.fillStyle   = `rgb(${dvmTheme['main-color']})`;      
            sectionAvailable.normal.none.strokeStyle = `rgb(${dvmTheme['main-color']})`;

            // AVAILABLE HOVER
            sectionAvailable.hover!.none.fillStyle   = `rgb(${dvmTheme['secondary-color']})`;
            sectionAvailable.hover!.none.strokeStyle = `rgb(${dvmTheme['secondary-color']})`;

            // SELECTED NORMAL
            sectionSelected.normal.none.fillStyle   =  `rgb(${dvmTheme['selected-color']})`;
            sectionSelected.normal.none.strokeStyle =  `rgb(${dvmTheme['selected-color']})`;
            sectionSelected.normal.none.fillOpacity =   0.8;

            // SELECTED HOVER
            sectionSelected.hover!.none.fillStyle    =  `rgb(${dvmTheme['selected-color']})`;
            sectionSelected.hover!.none.strokeStyle  =  `rgb(${dvmTheme['selected-color']})`;
            sectionSelected.hover!.none.fillOpacity  =  0.5;

      styles[0]['section']['available'] = sectionAvailable;
      styles[0]['section']['selected']  = sectionSelected;

    }

    // SEAT STYLES
    if(styles[0] && styles[0]['seat']){
      
      const seatAvailable: MapViewerStylesState = styles[0]['seat']['available']! as MapViewerStylesState,
            seatSelected:  MapViewerStylesState = styles[0]['seat']['selected']! as MapViewerStylesState;
      
            // AVAILABLE HOVER
            seatAvailable.hover!.none.fillStyle   = `rgb(${dvmTheme['selected-color']})`;
            seatAvailable.hover!.none.strokeStyle = `rgb(${dvmTheme['selected-color']})`;

            // SELECTED NORMAL
            seatSelected.normal.none.fillStyle   =  `rgb(${dvmTheme['selected-color']})`;
            seatSelected.normal.none.strokeStyle =  `rgb(${dvmTheme['selected-color']})`;
            seatSelected.normal.none.fillOpacity =  0.8;

            // CUSTOM STYLES
            if(customStyles){

              for(const entry of Object.entries(customStyles)){
                const [key, code] = entry;                

                // AVAILABLE NORMAL
                seatAvailable.normal[key] = {
                  fillStyle:    code.color,
                  strokeStyle:  code.color,
                  lineWidth:    0.3,
                  text:         true,
                  textFontSize: 1,
                  cursor:       'pointer',
                }
                
                // AVAILABLE HOVER
                seatAvailable.hover![key] = {
                  fillStyle:    `rgb(${dvmTheme['selected-color']})`,
                  strokeStyle:  `rgb(${dvmTheme['selected-color']})`,
                  lineWidth:    0.3,
                  text:         true,
                  textFontSize: 1,
                  cursor:       'pointer'
                }

              }
              
            }

      styles[0]['seat']['available'] = seatAvailable;
      styles[0]['seat']['selected']  = seatSelected;

    }

    // SET STYLES
    this.viewer.service.setStyles(styles);

  }
}
