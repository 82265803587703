import { Component, EffectRef, OnDestroy, OnInit,Signal, WritableSignal, computed, effect, inject, signal } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AssociationService } from 'src/app/core/services/association.service';
import { AvailabilityService } from 'src/app/core/services/availability.service';
import { EventService } from 'src/app/core/services/override/availability/event.service';
import { PackageService } from 'src/app/core/services/override/availability/package.service';
import { AssociationCollection } from 'src/app/shared/models/association.model';
import { SeatCollection } from 'src/app/shared/models/availabilty/seat.model';
import { PriceScaleCollection } from 'src/app/shared/models/availabilty/section.model';
import { Event } from 'src/app/shared/models/event.model';
import { Package } from 'src/app/shared/models/package.model';

@Component({
  selector: 'app-select-seat',
  templateUrl: './select-seat.component.html',
  styleUrls: ['./select-seat.component.css'],
})
export class SelectSeatComponent implements OnInit, OnDestroy {

  constructor(
    private availabilityService:  AvailabilityService<Event | Package, PriceScaleCollection | SeatCollection>,
  ){}
  
  private associationService:      AssociationService = inject(AssociationService); 
  private route:                   ActivatedRoute     = inject(ActivatedRoute);     

  protected type!:                 'event' | 'package' | 'exchange';
  protected entity$!:              Signal<Event | Package | null>;
  protected availability!:         Signal<PriceScaleCollection>                  
  protected associations:          Signal<AssociationCollection | null>  = this.associationService.selected;
  protected isSelectSeat:          WritableSignal<boolean|null>          = signal(null);

  protected entity:                Signal<Event | Package> = computed(()=> this.entity$() as Event | Package);
  protected entityCanToggleSas:    Signal<boolean> = computed((): boolean => {
    
    //Si es Exchange directamente es false ya que siempre forzamos SelectSeat
    if(this.type === 'exchange'){
      return false;
    }

    // Determinamos si la entity es un evento si tiene la propiedad "select_a_seat_allowed" y si es true
    if(this.entity$()){

      // Si es evento tendrá la propiedad "select_a_seat_allowed", devolvemos el valor de la propiedad
      if((this.entity$() as Event).hasOwnProperty('select_a_seat_allowed')){
        const event = this.entity$() as Event;
        return event.select_a_seat_allowed;
      }

      // Si no, es package y devolvemos true en todos los casos
      return true;


    }

    // Mientras no haya entity devolvemos false
    return false;
    
  });

  protected get hasEntity():       boolean {
    return this.entity$() ? true : false;
  }

  protected get hasAvailability(): boolean {
    return Object.values(this.availability()).length > 0 ? true : false;
  }

  protected get hasAssociations(): boolean {
    return Object.keys(this.associations()!).length ? true : false;
  }
 
  /**
   * Inicia el componente
   */
  ngOnInit(): void {
    this.initComponent()
  }

  /**
   * Destruye el componente.
   */
  ngOnDestroy(): void {
    this.destroyComponent()
  }

  /**
   * Comprueba si la routa contiene la propiedad "data" y asigna el valor a la propiedad "type" para discernir si se trata de un evento o un paquete etc.
   * Asigna el valor de la entidad seleccionada y la disponibilidad a las propiedades "entity" y "availability" respectivamente.
   */
  private initComponent(): void {
    this.route.data.subscribe(data => this.type = data['type']).unsubscribe()
    
    this.entity$       = this.availabilityService.entitySelected;
    this.availability = this.availabilityService.availability;

    if(this.type === 'exchange'){
      this.changeMode.destroy();
    }
  }

  /**
   * Destruye el efecto "changeMode".
   */
  private destroyComponent(): void {
    
    if(this.isSelectSeat() === true){
      this.toggleSelectSeat(false);
    }

    this.changeMode.destroy();
  }

  private toggleSelectSeat(status: boolean): void {
    const service = this.availabilityService as EventService | PackageService;
    service.setSelectSeatStatus(status);
    service.refresh();
  }
  
  /**
   * Comprueba si la propiedad "isSelectSeat" no es null (se ha modificado) y si es así, 
   * asigna el valor a la propiedad "isSelectSeat" del servicio de disponibilidad y refresca la disponibilidad.
   */
  private changeMode: EffectRef = effect(() => {
    if(this.isSelectSeat() != null){
      this.toggleSelectSeat(this.isSelectSeat()!);
      return;
    }
  },{allowSignalWrites: true})
} 
