import { Component, Input, EventEmitter, Output, WritableSignal, signal, OnChanges} from '@angular/core';
import { AssociationComplete } from 'src/app/shared/models/association.model';
import { PriceScale } from 'src/app/shared/models/availabilty/section.model';
import { Cart, CustomerCart, Item } from 'src/app/shared/models/cart.model';

@Component({
  selector: 'app-price-selector',
  templateUrl: './price-selector.component.html',
  styleUrl: './price-selector.component.css'
})
export class PriceSelectorComponent implements OnChanges {

  @Input()  user!:        AssociationComplete;                              // Usuario al que va adscrito el selector de Buyer Type.
  @Input()  priceScale!:  PriceScale;                               // Price Scale seleccionada.
  @Input()  disabled:     boolean           = false;                // Habilita o deshabilita botones de cantidad si max ticket alcanzado. 

  @Output() update:       EventEmitter<Cart>   = new EventEmitter(); // Emisor de Eventos -> Actualiza el carrito.

  protected show:         boolean              = true;               // Muestra o esconde los precios y selector de cantidades.
  protected cart:         WritableSignal<Cart> = signal({});         // Carrito creado a partir de las cantidades y el buyer type seleccionado.

  /**
   * Seteamos un carrito nuevo cada vez que la section (price scale) cambie.
   */
  ngOnChanges(): void {
   this.priceScale ? this.setCart() : false;
  }

  /**
   * Muestra o esconde el selector de cantidades
   */
  public toggleSelector(): void {
    this.show = !this.show;
  }

  /**
   * Actualiza el carrito incrementando en 1 la cantidad del buyer type seleccionado.
   * Llama a update cart y emite el carro actualizado.
   * @param {number} id - Buyer Type Id
   */
  protected onAdd(id: number): void {
    this.cart.update(cart => {
      cart[this.user.associate_id][id].num_tickets! += 1;
      return cart
    })

    this.updateCart();
  }

  /**
   * Actualiza el carrito restando en 1 la cantidad del buyer type seleccionado.
   * Si la cantidad es 0 retorna. Llama a update cart y emite el carro actualizado. 
   * @param {number} id - Buyer Type Id
   */
  protected onSubstract(id: number): void {
    if(this.cart()[this.user.associate_id][id].num_tickets === 0){
      return
    }else{
      this.cart.update(cart => {
        cart[this.user.associate_id][id].num_tickets! -= 1;
        return cart
      })
      
      this.updateCart();
    }
   
  }

  /**
   * Crea y setea el carrito utilizando los precios de la Price Scale seleccionada.
   * Formatea un objeto utilizando el ID de la Buyer Type y le añade la propiedad **quantity** la cual
   * se reflejará en el front y será la propiedad con la que se incremente o decremente respectivamente. 
   * 
   * @example cart = {1000: {code: X, name: Test, ..., quantity:0}, 1001:{...}}
   * 
   */
  private setCart(): void {
    const CustomerCart = {} as CustomerCart;

    Object.values(this.priceScale.prices).forEach(price => {
      let data: Item =  {...price, section: this.priceScale.sections[0], num_tickets:0};
      CustomerCart[price.id] = data;
    })

    this.cart()[this.user.associate_id] = CustomerCart;
  }

  /**
   * Este método es llamado por onAdd o onSubstract. Al actualizar la propiedad **quantity**
   * se envia como emit al componente padre para que este, con los datos del carrito actualizados,
   * opere como convenga.
   */
  private updateCart(): void{
    this.update.emit(this.cart());
  }
}
